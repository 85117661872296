import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Github = makeShortcode("Github");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "how-are-prices-determined",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-are-prices-determined",
        "aria-label": "how are prices determined permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How are prices determined?`}</h1>
    <p>{`As we learned in `}<a parentName="p" {...{
        "href": "/docs/honeyswap/protocol-overview"
      }}>{`Protocol Overview`}</a>{`, each pair on Uniswap is actually underpinned by a liquidity pool. Liquidity pools are smart contracts that hold balances of two unique tokens and enforces rules around depositing and withdrawing them. The primary rule is the `}<a parentName="p" {...{
        "href": "/docs/honeyswap/protocol-overview/glossary#constant-product-formula"
      }}>{`constant product formula`}</a>{`. When a token is withdrawn (bought), a proportional amount must be deposited (sold) to maintain the constant. The ratio of tokens in the pool, in combination with the constant product formula, ultimately determine the price that a swap executes at.`}</p>
    <h1 {...{
      "id": "how-uniswap-handles-prices",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-uniswap-handles-prices",
        "aria-label": "how uniswap handles prices permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How Uniswap handles prices`}</h1>
    <p>{`In Uniswap V1, trades are always executed at the “best possible” price, calcuated at execution time. Somewhat confusingly, this calculation is actually accomplished with one of two different formulas, depending on whether the trade specifies an exact `}<em parentName="p">{`input`}</em>{` or `}<em parentName="p">{`output`}</em>{` amount. Functionally, the difference between these two functions is miniscule, but the very existence of a difference increases conceptual complexity. Initial attempts to support both functions in V2 proved inelegant, and the decision was made to `}<strong parentName="p">{`not provide any pricing functions in the core`}</strong>{`. Instead, pairs directly check whether the invariant was satisfied (accounting for fees) after every trade. This means that rather than relying on a pricing function to `}<em parentName="p">{`also`}</em>{` enforce the invariant, V2 pairs simply and transparently ensure their own safety, a nice separation of concerns. One downstream benefit is that V2 pairs will more naturally support other flavors of trades which may emerge, (e.g. trading to a specific price at execution time).`}</p>
    <p>{`At a high level, in Uniswap V2, `}<em parentName="p">{`trades must be priced in the periphery`}</em>{`. The good news is that the `}<Link to='/docs/honeyswap/smart-contracts/library' mdxType="Link">{`library`}</Link>{` provides a variety of functions designed to make this quite simple, and all swapping functions in the `}<Link to='/docs/honeyswap/smart-contracts/library' mdxType="Link">{`router`}</Link>{` are designed with this in mind.`}</p>
    <h1 {...{
      "id": "pricing-trades",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#pricing-trades",
        "aria-label": "pricing trades permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pricing Trades`}</h1>
    <p>{`When swapping tokens on Uniswap, it’s common to want to receive as many output tokens as possible for an `}<em parentName="p">{`exact input amount`}</em>{`, or to pay as few input tokens as possible for an `}<em parentName="p">{`exact output amount`}</em>{`. In order to calculate these amounts, a contract must look up the `}<em parentName="p">{`current reserves`}</em>{` of a pair, in order to understand what the current price is. However, it is `}<em parentName="p">{`not safe to perform this lookup and rely on the results without access to an external price`}</em>{`.`}</p>
    <p>{`Say a smart contract naively wants to send 10 DAI to the DAI/WETH pair and receive as much WETH as it can get, given the current reserve ratio. If, when called, the native smart contract simply looks up the current price and executes the trade, it is `}<em parentName="p">{`vulnerable to front-running and will likely suffer an economic loss`}</em>{`. To see why, consider a malicious actor who seees this transaction before it is confirmed. They could execute a swap which dramatically changes the DAI/WETH price immediately before the naive swap goes through, wait for the naive swap to execute at a bad rate, and then swap to change the price back to what it was before the naive swap. This attack is fairly cheap and low-risk, and can typically be performed for a profit.`}</p>
    <p>{`To prevent these types of attacks, it’s vital to submit swaps `}<em parentName="p">{`that have access to knowledge about the “fair” price their swap should execute at`}</em>{`. In other words, swaps need access to an `}<em parentName="p">{`oracle`}</em>{`, to be sure that the best execution they can get from Uniswap is close enough to what the oracle considers the “true” price. While this may sound complicated, the oracle can be as simple as an `}<em parentName="p">{`off-chain observation of the current market price of a pair`}</em>{`. Because of arbitrage, it’s typically the case that the ratio of the intra-block reserves of a pair is close to the “true” market price. So, if a user submits a trade with this knowledge in mind, they can ensure that the losses due to front-running are tightly bounded. This is how, for example, the Uniswap frontend ensure trade safety. It calculates the optimal input/output amounts given observed intra-block prices, and uses the router to perform the swap, which guarantees the swap will execute at a rate no less that `}<inlineCode parentName="p">{`x`}</inlineCode>{`% worse than the observed intra-block rate, where `}<inlineCode parentName="p">{`x`}</inlineCode>{` is a user-specified slippage tolerance (0.5% by default).`}</p>
    <p>{`There are, of course, other options for oracles, including `}<Link to='/docs/honeyswap/core-concepts/oracles' mdxType="Link">{`native Uniswap V2 oracles`}</Link>{`.`}</p>
    <h2 {...{
      "id": "exact-input",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#exact-input",
        "aria-label": "exact input permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Exact Input`}</h2>
    <p>{`If you’d like to send an exact amount of input tokens in exchange for as many output tokens as possible, you’ll want to use `}<Link to='/docs/honeyswap/smart-contracts/router02/#getamountsout' mdxType="Link">{`getAmountsOut`}</Link>{`. The equivalent SDK function is `}<Link to='/docs/honeyswap/sdk/pair/#getoutputamount' mdxType="Link">{`getOutputAmount`}</Link>{`, or `}<Link to='/docs/honeyswap/sdk/trade/#minimumamountout' mdxType="Link">{`minimumAmountOut`}</Link>{` for slippage calculations.`}</p>
    <h2 {...{
      "id": "exact-output",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#exact-output",
        "aria-label": "exact output permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Exact Output`}</h2>
    <p>{`If you’d like to receive an exact amount of output tokens for as few input tokens as possible, you’ll want to use `}<Link to='/docs/honeyswap/smart-contracts/router02/#getamountsin' mdxType="Link">{`getAmountsIn`}</Link>{`. The equivalent SDK function is `}<Link to='/docs/honeyswap/sdk/pair/#getinputamount' mdxType="Link">{`getInputAmount`}</Link>{`, or `}<Link to='/docs/honeyswap/sdk/trade/#maximumamountin' mdxType="Link">{`maximumAmountIn`}</Link>{` for slippage calculations.`}</p>
    <h2 {...{
      "id": "swap-to-price",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#swap-to-price",
        "aria-label": "swap to price permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Swap to Price`}</h2>
    <p>{`For more this more advanced use case see `}<Github href="https://github.com/Uniswap/uniswap-v2-periphery/blob/master/contracts/examples/ExampleSwapToPrice.sol" mdxType="Github">{`ExampleSwapToPrice.sol`}</Github>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      